var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { fetchUtils } from "ra-core";
import jwt_decode from "jwt-decode";
import { localStorageManager } from "./utils";
/**
 * Check access token for every query to backend
 *
 * Rredirect to login if token is invalid
 *
 * Auto renew access token
 * Login email post data: { authType: email, username: "username", password: "password"}
 * Login email wallet data: { authType: wallet,  walletRewardAddress: "stake1uxww77x845hta4udeaz3s7mt6v3k3cq3qsrw7zy69hjrknc949k54",
    signature: {
        "signature": "84582aa201276761646472657373581de19cef78c7ad2ebed78dcf45187b6bd32368e0110406ef089a2de43b4fa166686173686564f458247061617378376a64364f38645945653245365042756466646165797643387a4a697132435840f0ac7c840ab458ee213b17f5fee38be393944d2894d9bd8a54ba8b6841379f0589d6544fa0b2bd6a2a2576bd88cabbaff01a36b080273a48b1738cec8aacbd08",
        "key": "a40101032720062158200d85a6c0f50c37278214c70e9b4a40edb75d5910f541d22bf3755240af9a5c7e"
    }}
 **/
var authProvider = function (loginUrl, renewTokenUrl, logoutUrl, loginWalletUrl) { return ({
    login: function (data) {
        if (data.authType === "email")
            return fetchUtils
                .fetchJson(loginUrl, {
                method: "POST",
                body: JSON.stringify({
                    username: data.username,
                    password: data.password,
                }),
            })
                .then(function (_a) {
                var json = _a.json;
                localStorageManager.setItems(json.username, json.accessToken, json.refreshToken, json.fullName);
            })
                .catch(function (err) { return err; });
        if (data.authType === "wallet")
            return fetchUtils
                .fetchJson(loginWalletUrl, {
                method: "POST",
                body: JSON.stringify({
                    walletRewardAddress: data.walletRewardAddress,
                    signature: data.signature,
                }),
            })
                .then(function (_a) {
                var json = _a.json;
                localStorageManager.setItems(json.username, json.accessToken, json.refreshToken, json.fullName);
            })
                .catch(function (err) { return err; });
    },
    logout: function () { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken, decodedAccessToken, isExpiredAccessToken, options, data, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    accessToken = localStorageManager.getItem("accessToken");
                    localStorageManager.removeItems();
                    try {
                        decodedAccessToken = jwt_decode(accessToken);
                        isExpiredAccessToken = Date.now() >= decodedAccessToken.exp * 1000;
                    }
                    catch (err) {
                        return [2 /*return*/, Promise.resolve()];
                    }
                    if (!accessToken || isExpiredAccessToken) {
                        return [2 /*return*/, Promise.resolve()];
                    }
                    options = {
                        headers: new Headers({
                            Authorization: "Bearer ".concat(accessToken),
                        }),
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, fetchUtils.fetchJson(logoutUrl, options)];
                case 2:
                    data = _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.log(error_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/, Promise.resolve()];
            }
        });
    }); },
    checkError: function (error) {
        //access token expire after standBy then require login
        if (error.status === 401 || error.status === 403) {
            localStorageManager.removeItems();
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, accessToken, refreshToken, decodedAccessToken, isExpiredAccessToken, decodedJwtRefresh, isExpiredJwtRefresh, options, data, error_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, localStorageManager.getItems()];
                case 1:
                    _a = _b.sent(), accessToken = _a.accessToken, refreshToken = _a.refreshToken;
                    if (!accessToken || !refreshToken) {
                        return [2 /*return*/, Promise.reject()];
                    }
                    decodedAccessToken = jwt_decode(accessToken);
                    isExpiredAccessToken = Date.now() >= decodedAccessToken.exp * 1000 - 600000;
                    decodedJwtRefresh = jwt_decode(refreshToken);
                    isExpiredJwtRefresh = Date.now() >= decodedJwtRefresh.exp * 1000 - 60000;
                    if (isExpiredJwtRefresh) {
                        return [2 /*return*/, Promise.reject()];
                    }
                    if (!isExpiredAccessToken) return [3 /*break*/, 5];
                    options = {
                        headers: new Headers({
                            Authorization: "Bearer ".concat(refreshToken),
                        }),
                    };
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, fetchUtils.fetchJson(renewTokenUrl, options)];
                case 3:
                    data = _b.sent();
                    if (!data)
                        return [2 /*return*/, Promise.reject()];
                    localStorageManager.setItem("accessToken", data.json.accessToken);
                    localStorageManager.setItem("refreshToken", data.json.refreshToken);
                    return [2 /*return*/, Promise.resolve()];
                case 4:
                    error_2 = _b.sent();
                    return [2 /*return*/, Promise.reject()];
                case 5: return [2 /*return*/];
            }
        });
    }); },
    getPermissions: function () { return Promise.reject("Unknown method"); },
    getIdentity: function () {
        return Promise.resolve({
            id: "user",
            fullName: localStorageManager.getItem("fullName"),
        });
    },
}); };
export default authProvider;
